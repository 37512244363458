/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Amplify */
//@import '~@aws-amplify/ui-angular/theme.css';

.list-bottom-button {
  position: relative;
  left: calc(50% - 30px);
  --border-radius: 100%;
  --padding-start: 14px;
  --padding-end: 14px;
  --box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
}

.ion-tabs-mobile .list-bottom-button {
  display: none;
}

.ion-tabs-mobile .desktop-tab-bar {
  display: none;
}

.ion-tabs-desktop .list-fab-button {
  display: none;
}

.ion-tabs-desktop .mobile-tab-bar {
  display: none;
}

.transactions-container-mobile .data-table-wrapper {
  max-height: 80vh;
  overflow: auto;
}

.transactions-container-desktop .data-table-wrapper {
  max-height: 85vh;
  overflow: auto;
}

.transactions-grid {
  --ion-grid-columns: 12;
}

.budget-table-wrapper {
  max-height: 92vh !important;
}

.data-table {
  position: relative;
}

ion-row.table-header {
  background-color: var(--ion-color-primary);
  color: white;
  font-variant: all-petite-caps;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 99;
}

ion-row.table-graph-header {
  background-color: var(--ion-color-primary);
  color: white;
  font-variant: all-petite-caps;
  font-weight: bold;
  position: sticky;
}

ion-row.table-graph-subheader {
  background-color: darkgrey;
  font-size: small;
  color: white;
}

ion-row.table-subheader {
  background-color: #4DA6FF;
  font-weight: bold;
  position: sticky;
  z-index: 99;
}

ion-row.table-insert {
  background-color: white;
  font-size: small;
  position: sticky;
  z-index: 99;
}

ion-row.table-insert ion-input {
  --background: floralwhite;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.create-inline-field {
  background: floralwhite;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}

.invalid-field {
  background: var(--ion-color-danger) !important;
}

ion-row.table-data-row:nth-of-type(odd) {
  background: #f0f0f2;
}

ion-row.table-data-row:nth-of-type(even) {
  background: white;
}

.data-table ion-col {
  border: 1px solid lightgray;
  border-bottom: 0;
  border-right: 0;
}

.data-table ion-col:last-child {
  border-right: 1px solid lightgray;
}

.data-table ion-row:last-child {
  border-bottom: 1px solid lightgray;
}

.table-data-row {
  font-size: small;
  color: rgb(92, 92, 92);
}

.table-data-button {
  text-align: center;
}

.data-table-header-element {
  --box-shadow: none;
  --border-width: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

.relative-element {
  position: relative;
}

.list-header {
  text-align: center;
}

.budget-list-header-select::part(icon) {
  display: none !important;
}

.in-budget-header-mobile {
  display: inline-block;
  margin-right: 10px;
  padding-bottom: 12px;
}

.in-budget-header-mobile.chevron-left {
  position: absolute;
  left: 5%;
  top: 25%
}

.in-budget-header-mobile.chevron-right {
  position: absolute;
  right: 5%;
  top: 25%
}

.budget-month-mobile {
  display: inline-block;
}

.amount-operation {
  font-size: large;
}

.custom-numeric-input input::-webkit-inner-spin-button,
.custom-numeric-input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.custom-numeric-input input {
  -moz-appearance:textfield !important;
}

.create-inline-popover {
  --min-width: 350px;
  max-height: 500px;
}

.update-inline-popover {
  --min-width: 350px;
  max-height: 500px;
}

#update-inline-payee-field {
  padding-left: 0;
}

#create-inline-open-direction-input ion-button {
  text-transform: none !important;
}

.budget-date-table-header {
  text-align: center;
  color: white;
  font-variant: small-caps;
}

.budget-date-table-subheader {
  text-align: left;
  color: white;
  font-variant: all-petite-caps;
}

.category-key-field {
  text-align: right !important;
  padding-right: 20px;
}

.inner-table-field {
  border-width: 0 !important;
}

.inner-table-field-not-formatted {
  padding: 0;
  margin: 0;
  border: none !important;
}

.direction-insert-symbol {
  padding-top: 10px;
}

.wand {
  margin-top: 50%;
}

.varying-size-option-select-popover {
  --width: max-content;
}

.transactions-popover-template {
  --width: max-content;
}

.transactions-popover-template-center {
  --offset-x: -500px;
}

.transactions-popover-template-right {
  --offset-x: -1110px;
}

.not-budgeted-badge {
  margin-top: 5px;
}

.section-title {
  color: white;
  font-variant: all-petite-caps;
  font-weight: bold;
}

.four-button-alert.md {
  .alert-button-group-vertical {
    display: block;

    button:nth-of-type(1),
    button:nth-of-type(2) {
      float: left;
      color: var(--ion-color-medium);
    }

    button:nth-of-type(3),
    button:nth-of-type(4) {
      float: right;
    }
  }
}

.four-button-alert.ios {
  .alert-button-group-vertical {
    flex-direction: row !important;

    button:nth-of-type(1) {
      color: var(--ion-color-medium);
    }

    button:nth-of-type(2) {
      color: var(--ion-color-medium);
      border-right: none;
    }
  }
}

.four-button-alert-input div {
  font-size: 13px !important;
}

.selector-not-selected {
  color: darkgrey !important;
}

.date-buttons {
  text-align: center;
  font-variant: small-caps;
  text-decoration: underline;
  color: var(--ion-color-primary);
}

.timeframe-modal {
  --height: 330px;
}

.neutral-style-item {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 13px;

  --background: transparent;
  --border-width: 0px;
  --border-color: transparent;

  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;

  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;

  --min-height: 0;
}

.neutral-style-item ion-label {
  padding: 0;
  margin: 0;
  border: 0;
  text-align: right;
}

.clickable-content {
  cursor: pointer;
}

.modal-title {
  text-align: center;
}

:root {
  /*
  --ion-color-primary: #03A9F4;
  --ion-color-primary-rgb: 3, 169, 244;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #005a00;
  --ion-color-secondary-tint: #1a751a;
   */
}
